import { Outlet, useMatches } from "react-router-dom";
import { useState } from "react";
import PausedClusterQueuesNotice from "app/components/shared/PausedClusterQueuesNotice";
import BuildContentNavigation from "../components/BuildContentNavigation";
import { BuildView } from "..";
import { useBuild } from "app/components/Playground/BuildContext";
import Header from "app/components/build/Header";
import Sidebar from "../components/Sidebar";
import { PipelineInfo } from "../../Header/PipelineInfo";
import { Branch } from "../../Header/Branch";
import { Commit } from "../../Header/Commit";
import BuildDuration from "../../Duration";
import PipelineStateIcon, {
  VARIANTS,
} from "app/components/shared/PipelineStateIcon/PipelineStateIcon";
import { twMerge } from "tailwind-merge";
import { StatusBar } from "../components/StatusBar";
import { getBuildColor } from "../lib/getBuildColor";
import { BuildActions } from "../components/BuildActions";
import { Trigger } from "../../Header/Trigger";
import BuildMessage from "../../Message";
import BuildNumber from "../../Header/BuildNumber";
import { PullRequest } from "../../Header/PullRequest";
import Icon from "app/components/shared/Icon";

/**
 * Build page layout.
 */
export default function BuildShowLayout() {
  const matches = useMatches();
  const { build, store } = useBuild();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  if (!build || !store) {
    throw new Error("Missing build context");
  }

  const view = [...matches].pop()?.id as BuildView;
  const theme = getBuildColor(build);

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  return (
    <>
      <PausedClusterQueuesNotice
        pausedQueues={build.dispatchPausedClusterQueues}
      />

      {Features.BuildSidebar ? (
        // Negative top margin to align new build page layout borders
        <div className="flex flex-auto flex-col md:flex-row gap-3 -mt-2.5">
          {/* Sidebar - Visible on MD and above */}
          <div className="md:flex w-80 flex-col gap-2">
            <PipelineInfo />
            <div className="relative h-full">
              <div className="absolute -left-2 -right-2 px-2 h-full overflow-y-auto no-scrollbar">
                <Sidebar />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 flex-auto relative">
            {/* Mobile Sidebar - Visible on MD and below */}
            <div
              className={`md:hidden absolute grid grid-rows-[auto_1fr] h-full inset-0 z-50 bg-white transition-transform duration-75 ease-in-out ${
                isMobileSidebarOpen
                  ? "translate-x-0"
                  : "-translate-x-[calc(100%+2rem)]"
              }`}
            >
              <div className="mb-2 border-b flex justify-between items-center">
                <h3 className="text-base font-semibold">Step list</h3>
                <button
                  className="px-2 py-1.5 bg-purple-100 text-purple-600 rounded flex items-center self-start"
                  onClick={toggleMobileSidebar}
                >
                  <Icon
                    icon="heroicons/20/solid/x-mark"
                    style={{ height: 16, width: 16 }}
                    className="mr-2 mt-0.5"
                  />
                  Close
                </button>
              </div>
              <div className="overflow-y-auto">
                <Sidebar />
              </div>
            </div>

            <div className="flex justify-between flex-col lg:flex-row">
              <div className="flex flex-1 gap-2 mb-2 lg:mb-0">
                <div className="flex flex-1 gap-x-4 gap-y-2 flex-wrap">
                  <BuildNumber {...build} currentView={view} />
                  {build.branchName && (
                    <Branch className="shrink-0 text-xs" {...build} />
                  )}
                  {build.commitId && (
                    <span className="flex items-center gap-1 text-xs">
                      <Commit {...build} />

                      {build.pullRequest && (
                        <span>
                          (<PullRequest {...build} />)
                        </span>
                      )}
                    </span>
                  )}

                  <button
                    className="md:hidden px-2 py-1.5 bg-gray-100 hover:bg-purple-100 hover:text-purple-600 rounded self-start"
                    onClick={toggleMobileSidebar}
                  >
                    <Icon
                      icon="heroicons/outline/bars-3-bottom-left"
                      style={{ height: 16, width: 16 }}
                      className="mr-2"
                    />
                    Step list
                  </button>
                </div>
              </div>

              <div className="shrink-0 flex gap-1 items-center justify-between lg:justify-end">
                <BuildActions />
              </div>
            </div>

            <div
              className="flex flex-col flex-auto rounded-md border border-t-0 relative"
              style={{
                borderColor: theme.primaryColor,
              }}
            >
              <div className="z-[1]">
                <div className="rounded-t-md overflow-hidden">
                  <StatusBar />
                </div>

                <div
                  className="flex flex-col px-4 pt-3 pb-3 border-b"
                  style={{
                    borderColor: theme.secondaryColor,
                    backgroundColor: theme.tertiaryColor,
                  }}
                >
                  <div className="flex flex-col flex-auto">
                    <div className="flex flex-auto">
                      <div className="flex-auto">
                        <h3
                          className={twMerge(
                            "max-w-prose text-base m-0",
                            build.message && "truncate",
                          )}
                        >
                          <BuildMessage
                            className="text-inherit focus:text-inherit hover:text-inherit"
                            url={build.commitUrl}
                            message={build.message}
                          />
                        </h3>
                      </div>

                      <div className="flex flex-col shrink-0 self-stretch gap-2 pl-4">
                        <div className="flex gap-2 items-center">
                          <div className="rounded-md py-1">
                            <BuildDuration build={build} />
                          </div>

                          <PipelineStateIcon
                            build={build}
                            style={{
                              width: "24px",
                              height: "24px",
                              color: theme.primaryColor,
                            }}
                            variant={VARIANTS.large}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-x-1 gap-y-1 items-center flex-wrap text-xs">
                      <Trigger {...build} />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between px-4 border-b border-gray-400 shadow-sm">
                  <BuildContentNavigation currentView={view} />
                </div>
              </div>

              <div className="flex-auto relative">
                <div className="flex w-full h-full absolute overflow-y-auto">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Header
            build={build}
            showRebuild={true}
            store={store}
            currentView={view}
            showJobs={view === BuildView.JobList}
          />
          <BuildContentNavigation currentView={view} />
          <Outlet />
        </>
      )}
    </>
  );
}
